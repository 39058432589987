import * as React from 'react'

const Hero = ({ pretitle, title }) => (
  <section className="richtext container padding">
    <p role="doc-subtitle">{pretitle}</p>
    <h1 className="h1">{title}</h1>
  </section>
)

export default Hero

import * as React from 'react'
import * as css from './QuotingTools.module.css'
import { useAuth0 } from '@auth0/auth0-react'
import { useSso } from 'hooks/useSso'
import slugify from 'slugify'
import { Link } from 'gatsby'
import newtab from 'utils/newtab'
import SanityBlock from 'components/common/SanityBlock'
import { MdKeyboardArrowRight } from 'react-icons/md'
import Cta from 'components/common/CTA'
import clsx from 'clsx'

function slug(str) {
  return slugify(str, { lower: true })
}

const Tool = ({ title, _rawDescription, _rawNotice, cta, additional }) => {
  const { user }  = useAuth0()
  const sso = useSso(title, user)
  const [$url, set$url] = React.useState(sso.url || cta?.url)

  React.useEffect(() => {
    if (!!sso.getUrl) {
      (async () => {
        set$url(await sso.getUrl())
      })()
    }
  })

  return (
    <article id={slug(title)} className={`${css.article} richtext`}>
      <h3 className="h2">{title}</h3>

      <SanityBlock className={css.desc} body={_rawDescription} />

      {!!$url && <div>
        <Cta {...cta} url={$url} label={cta?.label || `Access ${title}`} />
      </div>}

      {additional.map((item, key) => (
        <div key={key}>
          <a className="link with-icon" href={item.file?.asset.url || item.url} {...newtab}>
            {item.title}
            <MdKeyboardArrowRight />
          </a>
        </div>
      ))}

      {_rawNotice && (
        <SanityBlock className={css.notice} body={_rawNotice} />
      )}
    </article>
  )
}

const QuotingTools = ({ title, description, items, location }) => {
  const hash = location.hash.substr(1)

  return (
    <section className="container padding">
      <header className={`${css.header} richtext`}>
        {title && <h2 className="uppercase">{title}</h2>}
        {description && <p>{description}</p>}
      </header>

      <div className={css.grid}>
        <div className={css.toc}>
          <ul className="sticky">
            {items.map((item, key) => (
              <li key={key}>
                <Link className={clsx(css.nav, hash === slug(item.title) && css.active)} to={`#${slug(item.title)}`}>
                  {item.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>

        <div>
          {items.map((item, key) => (
            <Tool {...item} key={key} />
          ))}
        </div>
      </div>
    </section>
  )
}

export default QuotingTools

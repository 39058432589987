import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import DefaultLayout from 'layouts/Default'
import LoadingLayout from 'layouts/Loading'
import Hero from 'components/pg-apply/Hero'
import QuotingTools from 'components/pg-quote/QuotingTools'

const App = ({ location }) => {
  const { sanityApplyPg: pg } = useStaticQuery(query)

  return (
    <DefaultLayout {...pg.seo}>
      <Hero {...pg.hero} />
      <QuotingTools {...pg.sso} location={location} />
    </DefaultLayout>
  )
}

const ApplyPg = withAuthenticationRequired(App, {
  onRedirecting: () => <LoadingLayout />
})

export default ApplyPg

const query = graphql`query ApplyPg {
  sanityApplyPg {
    hero {
      pretitle
      title
    }
    sso {
      description
      items {
        title
        _rawDescription
        _rawNotice
        cta { ...cta }
        additional {
          title
          url
          file { asset { url } }
        }
      }
    }
    seo {
      title
      description
    }
  }
}`
